<template>
  <div class="location-integration-settings">
    <ui-loading
      v-if="isLoading"
      inline
      class="location-integration-settings__loading"
    />

    <div v-else>
      <div
        v-for="integration in integrationsSettingsConfig"
        :key="integration[0].value"
        class="location-integration-settings__integration"
      >
        <div
          v-for="info in integration"
          :key="info.value"
          class="location-integration-settings__integration-row"
        >
          <div class="location-integration-settings__integration-row-label">
            {{ info.label }}
          </div>

          <div
            class="location-integration-settings__integration-row-value"
            :style="{
              color: info.color,
            }"
          >
            {{ info.value || '—' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@fullcalendar/core';
import api from '@/api';
import { INTEGRATION_LABEL, INTEGRATION_STATUS_CONFIG } from '@/config/integrations';

export default {
  name: 'LocationIntegrationSettings',

  props: {
    location: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      integrationSettings: null,
    };
  },

  computed: {
    integrationsSettingsConfig() {
      if (!this.integrationSettings) {
        return null;
      }

      return this.integrationSettings.map((integration) => ([
        {
          label: 'Integration name',
          value: INTEGRATION_LABEL[integration.integrationSystem],
        },
        {
          label: 'Altegio Salon Id',
          value: integration.externalId,
        },
        {
          label: 'Integration created',
          value: formatDate(integration.integrationCreated),
        },
        {
          label: 'Integration stopped',
          value: formatDate(integration.integrationStopped),
        },
        {
          label: 'Status',
          value: INTEGRATION_STATUS_CONFIG[integration.status]?.label,
          color: INTEGRATION_STATUS_CONFIG[integration.status]?.color,
        },
      ]));
    },
  },

  methods: {
    async getIntegrationSettings() {
      try {
        this.isLoading = true;

        const { data } = await api.locations.setIntegrationSettingsConfig(this.location.id);
        this.integrationSettings = data;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.isLoading = false;
      }
    },
  },

  mounted() {
    this.getIntegrationSettings();
  },
};
</script>

<style lang="scss">
.location-integration-settings {
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-height: 200px;

  &__loading {
    margin: auto;
  }

  &__integration {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: fit-content;

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 300px;

      font-size: 14px;
      line-height: 24px;
      font-weight: 400;

      &-label {
        opacity: 0.5;
      }
    }
  }
}
</style>
