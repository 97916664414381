/* eslint-disable no-underscore-dangle, class-methods-use-this, max-classes-per-file */

const CONFIG_PARAM = 'authConfig';

class ConfigPayload {
  /**
   * @type {string[]}
   */
  used = [];

  /**
   * @type {string[]}
   */
  all = [];

  /**
   * Constructor.
   *
   * @param {Object} config
   * @param {ApiInterceptor} interceptor
   */
  constructor(config, interceptor) {
    this.used = [config.baseURL];
    this.all = interceptor.items.map(({ baseURL }) => baseURL);
  }

  /**
   * Has not yet used baseURL.
   *
   * @returns {boolean}
   */
  get hasNext() {
    return this.all.some((baseURL) => !this.used.includes(baseURL));
  }

  /**
   * Add baseURL to used.
   *
   * @param {string} baseURL
   */
  append(baseURL) {
    if (!this.used.includes(baseURL)) {
      this.used.push(baseURL);
    }
  }

  /**
   * Returns request config with next baseURL.
   *
   * @param {Object} config
   * @returns {Object}
   */
  next(config) {
    const baseURL = this.all.find((url) => !this.used.includes(url));

    if (baseURL) {
      this.append(baseURL);

      return {
        ...config,
        baseURL,
      };
    }

    return false;
  }

  /**
   * Config has payload.
   *
   * @param {Object} config
   * @returns {boolean}
   */
  static hasPayload(config) {
    return Boolean(config[CONFIG_PARAM]);
  }

  /**
   * Returns exists payload or create.
   *
   * @param {Object} config
   * @param {ApiInterceptor} interceptor
   * @returns {ConfigPayload}
   */
  static resolve(config, interceptor) {
    if (!this.hasPayload(config)) {
      config[CONFIG_PARAM] = new this(config, interceptor);
    }

    return config[CONFIG_PARAM];
  }
}

export default class ApiInterceptor {
  /**
   * Stop Vue reactivity for this class.
   *
   * @type {boolean}
   */
  _isVue = true;

  /**
   * @type {string}
   */
  selectedValue = '';

  /**
   * Raw server list from config.
   *
   * @type {Object[]}
   */
  rawItems = [];

  /**
   * @type {Function}
   */
  onUpdate = null;

  /**
   * Constructor.
   *
   * @param {string} parameters.selected
   * @param {Object[]} parameters.items
   * @param {Function} parameters.onUpdate
   */
  constructor({ selected, items, onUpdate }) {
    this.selectedValue = selected || '';
    this.rawItems = items;
    this.onUpdate = onUpdate;
  }

  /**
   * Returns server list for interface.
   *
   * @returns {Object[]}
   */
  get items() {
    return this.rawItems.map((item) => ({
      ...item,
      isSelected: item.name === this.selectedValue,
    }));
  }

  /**
   * Returns selected server name.
   *
   * @returns {Object}
   */
  get selected() {
    return this.items.find(({ isSelected }) => isSelected);
  }

  /**
   * Set selected server by name.
   *
   * @param {string|Object} value
   */
  set selected(value) {
    if (typeof value === 'object') {
      value = value.name;
    }

    this.selectedValue = value;

    if (typeof this.onUpdate === 'function') {
      this.onUpdate(value);
    }
  }

  /**
   * Returns server by baseURL.
   *
   * @param {string} url
   * @returns {Object}
   */
  byBaseURL(url) {
    return this.items.find(({ baseURL }) => baseURL === url);
  }

  /**
   * Returns server for country.
   *
   * @returns {Object}
   */
  byCountry(iso) {
    return this.items.find(({ country }) => country.includes(iso)) || this.byCountry('default');
  }

  /**
   * Returns modified request config, replace baseURL if needed.
   *
   * @param {Object} config
   * @returns {Object}
   */
  onRequest(config) {
    if (!this.selected) {
      return config;
    }

    if (this.isProcessRequest(config)) {
      return config;
    }

    if (this.isUserRequest(config)) {
      return config;
    }

    config.baseURL = this.selected.baseURL;

    return config;
  }

  /**
   * Save called baseURL when authorization is successfull.
   *
   * @param {Object} config
   */
  onSuccess(config) {
    if (this.isAuthRequest(config)) {
      this.selected = this.byBaseURL(config.baseURL);
    }
  }

  /**
   * Handle throwing error and returns next request config or `false`.
   *
   * @param {any} response
   * @param {Object} config
   * @returns {boolean|Object}
   */
  onError(response, config) {
    if (!config) {
      return false;
    }

    const payload = ConfigPayload.resolve(config, this);

    if (!payload.hasNext) {
      return false;
    }

    if (this.isAuthError(response, config)) {
      return payload.next(config);
    }

    return false;
  }

  /**
   * Returns `true` when config is matched with specified url or regexp.
   *
   * @param {Object} config
   * @param {string} url
   * @returns {boolean}
   */
  isUrl(config, url) {
    if (url instanceof RegExp) {
      return url.test(config.url);
    }

    return config.url.indexOf(url) === 0;
  }

  /**
   * Returns `true` when config is matched with some specified urls.
   *
   * @param {Object} config
   * @param {string[]} urls
   * @returns {boolean}
   */
  someUrl(config, urls) {
    return urls.some((url) => this.isUrl(config, url));
  }

  /**
   * Request is process of handling.
   *
   * @param {Object} config
   * @returns {boolean}
   */
  isProcessRequest(config) {
    return ConfigPayload.hasPayload(config);
  }

  /**
   * Authorization request for search correct baseURL.
   *
   * @param {Object} config
   * @returns {boolean}
   */
  isAuthRequest(config) {
    return false;
  }

  /**
   * Disable api-switch for this requests.
   *
   * @param {Object} config
   * @returns {boolean}
   */
  isUserRequest(config) {
    return false;
  }

  /**
   * Network error.
   *
   * @param {any} response
   * @returns {boolean}
   */
  isNetworkError(response) {
    return !response;
  }

  /**
   * Authorization error and need switch to another baseURL.
   *
   * @param {Object} response
   * @param {Object} config
   * @returns {boolean}
   */
  isAuthError(response, config) {
    const { status } = response;

    return status > 299;
  }
}
