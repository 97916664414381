<template>
  <validation-observer tag="div" v-slot="{ validate }">
    <ui-card tag="form" :is-loading="loading || formLoading">
      <template #header>
        <h4 class="card-title">{{ title }}</h4>
      </template>

      <div class="form-group mt-0 mb-4">
        <ui-avatar
          :image="imageUrl"
          :title="$t('UI.avatar.locationTitle')"
          @save="uploadImage"
        />
      </div>

      <ui-tabs
        v-model="currentFormTab"
        :tabs="formTabList"
        type="bubble"
      >
        <template v-slot:[FORM_TABS.DEFAULT]>
          <div class="row mr-0">
            <div class="col-12 col-md-8 col-lg-6 col-xl-4">
              <validation-provider
                tag="div"
                :name="$t('form.labels.locationName')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  v-model="form.name"
                  :label="$t('form.labels.locationName')"
                  required
                  :error="errors[0]"
                />
              </validation-provider>

              <ui-input
                v-model="form.groupName"
                :label="$t('form.labels.groupName')"
              />

              <validation-provider
                tag="div"
                :name="$t('form.labels.country')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-select
                  v-model="form.country"
                  :items="countries"
                  :label="$t('form.labels.country')"
                  :placeholder="$t('form.placeholders.select')"
                  label-key="name"
                  value-key="iso"
                  filterable
                  required
                  :error="errors[0]"
                  :disabled="!editable"
                />
              </validation-provider>

              <validation-provider
                tag="div"
                :name="$t('form.labels.city')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  v-model="form.city"
                  :label="$t('form.labels.city')"
                  required
                  :error="errors[0]"
                />
              </validation-provider>

              <validation-provider
                tag="div"
                :name="$t('form.labels.address')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  v-model="form.address"
                  :label="$t('form.labels.address')"
                  required
                  :error="errors[0]"
                />
              </validation-provider>

              <validation-provider
                tag="div"
                :name="'Number of locations'"
                rules="required|numeric|min_value:1"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  :value="form.locationQuantity"
                  @input="form.locationQuantity = castInt($event)"
                  :label="'Number of locations'"
                  required
                  :error="errors[0]"
                />
              </validation-provider>

              <validation-provider
                tag="div"
                :name="$t('form.labels.staffQty')"
                rules="required|numeric|min_value:1"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  :value="form.staffQuantity"
                  @input="form.staffQuantity = castInt($event)"
                  :label="$t('form.labels.staffQty')"
                  required
                  :error="errors[0]"
                />
              </validation-provider>

              <validation-provider
                tag="div"
                :name="$t('form.labels.notes')"
                rules="max:140"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-input
                  v-model="form.notes"
                  :label="$t('form.labels.notes')"
                  tag="textarea"
                  rows="1"
                  maxlength="140"
                  :error="errors[0]"
                />
              </validation-provider>

              <validation-provider
                tag="div"
                :name="$t('form.labels.mode')"
                rules="required"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-select
                  v-model="form.mode"
                  :items="modes"
                  :label="$t('form.labels.mode')"
                  :placeholder="$t('form.placeholders.select')"
                  label-key="label"
                  value-key="id"
                  filterable
                  required
                  :error="errors[0]"
                  :disabled="!editable"
                />
              </validation-provider>

              <validation-provider
                tag="div"
                :name="$t('form.labels.partner')"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-select
                  v-model="partnerStatus"
                  :items="partnerStatuses"
                  :label="$t('form.labels.partner')"
                  :placeholder="$t('form.placeholders.select')"
                  label-key="label"
                  value-key="id"
                  filterable
                  :error="errors[0]"
                />
              </validation-provider>

              <template v-if="partnerStatus">
                <validation-provider
                  tag="div"
                  :name="$t('form.labels.partnerName')"
                  rules="required"
                  mode="passive"
                  v-slot="{ errors }"
                >
                  <ui-input
                    v-model="form.partner.name"
                    :label="$t('form.labels.partnerName')"
                    required
                    :error="errors[0]"
                  />
                </validation-provider>

                <div class="form-group mt-0 mb-5">
                  <ui-avatar
                    :image="partnerImageUrl"
                    :title="$t('UI.avatar.partnerTitle')"
                    @save="uploadPartnerImage"
                  />
                </div>
              </template>

              <ui-switcher
                v-if="showOnlineMenuEnabledSwitcher"
                v-model="form.isOnlineMenuEnabled"
                :label="$t('form.labels.onlineMenu')"
                class="mb-5"
              />

              <ui-switcher
                v-if="showQrBillEnabledSwitcher"
                v-model="form.isQrBillEnabled"
                :label="$t('form.labels.enableQrBill')"
                class="mb-5"
              />

              <ui-switcher
                v-if="isUk || isUAE"
                v-model="form.isEnableSubscriptionPlans"
                :label="$t('form.labels.enableSubscriptionPlans')"
                class="mb-5"
                @input="toggleSubscriptionPlansHandler"
              />

              <ui-switcher
                v-model="form.isMarketPlaceEnabled"
                :label="$t('form.labels.enableIntegrations')"
                class="mb-5"
              />
            </div>

            <location-business-type
              v-if="isUk || isGermany || isUAE || isSwitzerland"
              key="location-business-type"
              :form="form"
              :data="locationData"
              :is-onboarding-loading="loading"
              :editable="editable"
              @activate="activate"
              @completed="$emit('completed')"
            />
            <location-world
              v-else-if="form.country"
              :form="form"
              key="location-world"
            />
          </div>
        </template>

        <template v-slot:[FORM_TABS.QRBILL]>
          <div class="mb-4">
            <div class="mb-4 font-weight-bold text-grey">
              Fees
            </div>

            <div class="row mb-4">
              <div class="col-12 col-md-4 col-lg-4 col-xl-4">
                <validation-provider
                  tag="div"
                  :name="$t('form.labels.merchantFee')"
                  rules="required|float|min_value:0|max_value:100"
                  mode="passive"
                  v-slot="{ errors }"
                >
                  <ui-input
                    v-model="form[QR_BILL_COMMISSION_LABELS.MERCHANT_FEE]"
                    :label="$t('form.labels.merchantFee')"
                    class="mt-2 mb-2"
                    required
                    :error="errors[0]"
                  />
                </validation-provider>
              </div>

              <div class="col-12 col-md-4 col-lg-4 col-xl-4">
                <validation-provider
                  tag="div"
                  :name="$t('form.labels.gatewayFee', { currency: locationCurrency })"
                  rules="required|float|min_value:0"
                  mode="passive"
                  v-slot="{ errors }"
                >
                  <ui-input
                    v-model="form[QR_BILL_COMMISSION_LABELS.GATEWAY_FEE]"
                    :label="$t('form.labels.gatewayFee', { currency: locationCurrency })"
                    class="mt-2 mb-2"
                    required
                    :error="errors[0]"
                  />
                </validation-provider>
              </div>

              <div class="col-12 col-md-4 col-lg-4 col-xl-4">
                <validation-provider
                  tag="div"
                  :name="$t('form.labels.settlementFee', { currency: locationCurrency })"
                  rules="required|float|min_value:0"
                  mode="passive"
                  v-slot="{ errors }"
                >
                  <ui-input
                    v-model="form[QR_BILL_COMMISSION_LABELS.SETTLEMENT_FEE]"
                    :label="$t('form.labels.settlementFee', { currency: locationCurrency })"
                    class="mt-2 mb-2"
                    required
                    :error="errors[0]"
                  />
                </validation-provider>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12 col-md-4 col-lg-4 col-xl-4">
                <validation-provider
                  tag="div"
                  :name="$t('form.labels.refunds', { currency: locationCurrency })"
                  rules="required|float|min_value:0"
                  mode="passive"
                  v-slot="{ errors }"
                >
                  <ui-input
                    v-model="form[QR_BILL_COMMISSION_LABELS.REFUNDS]"
                    :label="$t('form.labels.refunds', { currency: locationCurrency })"
                    class="mt-2 mb-2"
                    required
                    :error="errors[0]"
                  />
                </validation-provider>
              </div>

              <div class="col-12 col-md-4 col-lg-4 col-xl-4">
                <validation-provider
                  tag="div"
                  :name="$t('form.labels.chargebacks', { currency: locationCurrency })"
                  rules="required|float|min_value:0"
                  mode="passive"
                  v-slot="{ errors }"
                >
                  <ui-input
                    v-model="form[QR_BILL_COMMISSION_LABELS.CHARGEBACKS]"
                    :label="$t('form.labels.chargebacks', { currency: locationCurrency })"
                    class="mt-2 mb-2"
                    required
                    :error="errors[0]"
                  />
                </validation-provider>
              </div>
            </div>

            <div class="mb-4 font-weight-bold text-grey">
              Settlements
            </div>

            <div class="row">
              <div class="col-12 col-md-4 col-lg-4 col-xl-4">
                <validation-provider
                  tag="div"
                  :name="$t('form.labels.daysUntilSettlement')"
                  rules="required|decimal|min_value:0"
                  mode="passive"
                  v-slot="{ errors }"
                >
                  <ui-input
                    v-model="form[QR_BILL_COMMISSION_LABELS.DAYS_UNTIL_SETTLEMENT]"
                    :label="$t('form.labels.daysUntilSettlement')"
                    class="mt-2 mb-2"
                    required
                    :error="errors[0]"
                  />
                </validation-provider>
              </div>

              <div class="col-12 col-md-4 col-lg-4 col-xl-4">
                <validation-provider
                  tag="div"
                  :name="$t('form.labels.minimumSettlementAmount', { currency: locationCurrency })"
                  rules="required|float|min_value:0"
                  mode="passive"
                  v-slot="{ errors }"
                >
                  <ui-input
                    v-model="form[QR_BILL_COMMISSION_LABELS.MINIMUM_SETTLEMENT_AMOUNT]"
                    :label="$t('form.labels.minimumSettlementAmount', { currency: locationCurrency })"
                    class="mt-2 mb-2"
                    required
                    :error="errors[0]"
                  />
                </validation-provider>
              </div>

              <div class="col-12 col-md-4 col-lg-4 col-xl-4">
                <validation-provider
                  tag="div"
                  :name="$t('form.labels.noLimitSettlementFee')"
                  rules="required|float|min_value:0|max_value:100"
                  mode="passive"
                  v-slot="{ errors }"
                >
                  <ui-input
                    v-model="form[QR_BILL_COMMISSION_LABELS.NO_LIMIT_SETTLEMENT_FEE]"
                    :label="$t('form.labels.noLimitSettlementFee')"
                    class="mt-2 mb-2"
                    required
                    :error="errors[0]"
                  />
                </validation-provider>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:[FORM_TABS.PAY_SETTINGS]>
          <location-pay-settings
            :location="form"
          />
        </template>

        <template v-slot:[FORM_TABS.INTEGRATION_SETTINGS]>
          <location-integration-settings
            :location="form"
          />
        </template>

        <template v-slot:[FORM_TABS.KYB]>
          <location-k-y-b-form
            :location-id="form.id"
          />
        </template>

        <template v-slot:[FORM_TABS.KYB_FILES]>
          <location-k-y-b-files
            :location-id="form.id"
          />
        </template>
      </ui-tabs>

      <div v-if="showMainSubmitActions" class="location-form__actions">
        <template v-if="formData">
          <ui-button
            action
            type="primary"
            :is-loading="loading"
            :disabled="loading"
            class="location-form__actions-button"
            @click.prevent="save(validate)"
          >
            {{ $t('form.buttons.save') }}
          </ui-button>

          <ui-button
            v-if="(formData.status === 'NEW' || formData.status === 'NOT_ACTIVE')"
            type="primary"
            :is-loading="loading"
            :disabled="loading"
            outline
            action
            class="location-form__actions-button"
            @click.prevent="activate"
          >
            {{ formData.status === 'NEW' ? $t('form.buttons.completeOnboarding') : $t('form.buttons.turnOnPay') }}
          </ui-button>

          <ui-button
            v-else-if="formData.status === 'ACTIVE'"
            type="primary"
            :is-loading="loading"
            :disabled="loading"
            @click.prevent="handleDeactivate"
            action
            outline
            class="location-form__actions-button"
          >
            {{ $t('form.buttons.turnOffPay') }}
          </ui-button>

          <ui-button
            v-if="isSubscriptionCountry(formData.country)"
            type="primary"
            outline
            action
            class="location-form__actions-button"
            @click.prevent="showSubscriptionManageModal = true"
          >
            Manage subscriptions
          </ui-button>

          <ui-button
            v-if="showCancel"
            native-type="button"
            :disabled="loading"
            class="location-form__actions-button"
            @click="cancel"
          >
            {{ $t('form.buttons.cancel') }}
          </ui-button>
        </template>

        <ui-button
          v-else
          action
          type="primary"
          :is-loading="loading"
          :disabled="loading"
          class="location-form__actions-button"
          @click.prevent="create(validate)"
        >
          {{ $t('form.buttons.create') }}
        </ui-button>
      </div>
    </ui-card>

    <location-turn-off-pay-modal
      v-model="turnOffPay"
      :data="locationData"
      @completed="deactivate"
    />

    <validation-observer tag="form" #default="{ invalid, handleSubmit }">
      <ui-modal-alert
        v-model="activateLocation.show"
        :title="'Onboarding complete date'"
        :apply-disabled="invalid"
        @apply="handleSubmit(() => activateLocation.callback(true))"
        @cancel="activateLocation.callback(false)"
      >
        <div class="row mb-0 mt-4">
          <div class="col-auto mx-auto">
            <validation-provider
              tag="div"
              :name="'Select a onboarding date'"
            >
              <ui-calendar-picker
                v-model="activateLocation.date"
                :min="minDate"
                :max="maxDate"
                :label="'Onboarding complete date'"
                :placeholder="'Select'"
                input-classes="text-center"
              />
            </validation-provider>
          </div>
        </div>
      </ui-modal-alert>
    </validation-observer>

    <subscription-manage-modal
      v-model="showSubscriptionManageModal"
      :owners="form.owners"
      :location-id="form.id"
      @submit="$emit('completed')"
      @close="showSubscriptionManageModal = false"
    />
  </validation-observer>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import api from '@/api';
import getCountry from '@/utils/getCountry';
import hex from '@/utils/hex';
import resetMixin from '@/mixins/reset-mixin';
import { DEFAULT_FEE_PLAN, EMIRATES_FEE_PLAN, UK_FEE_PLAN } from '@/config/feePlans';
import { QR_BILL_COMMISSION_LABELS, getQrBillCommissionConfig } from '@/config/qrBillCommission';
import { FORM_TABS } from '@/config/locationFormTabs';
import SubscriptionManageModal from '@/components/Subscription/SubscriptionManageModal.vue';
import { isSubscriptionCountry } from '@/config/subscription';
import toSQLDate from '@/utils/toSQLDate';
import getCurrency from '@/utils/getCurrency';
import UiTabs from '@/components/UI/Tabs/Tabs.vue';
import { PAYMENT_PAGE_TYPE } from '@/config/paymentPageType';
import LocationPaySettings from '@/components/Location/LocationPaySettings.vue';
import { PAYMENT_SYSTEM } from '@/config/paymentSystems';
import LocationKYBForm from '@/components/Location/LocationKYBForm.vue';
import LocationKYBFiles from '@/components/Location/LocationKYBFiles.vue';
import LocationIntegrationSettings from '@/components/Location/LocationIntegrationSettings.vue';
import LocationWorld from './LocationWorld.vue';
import LocationBusinessType from './LocationBusinessType.vue';
import LocationTurnOffPayModal from './LocationTurnOffPayModal.vue';

export default {

  inheritAttrs: true,

  components: {
    LocationIntegrationSettings,
    LocationKYBFiles,
    LocationKYBForm,
    LocationPaySettings,
    SubscriptionManageModal,
    LocationBusinessType,
    LocationWorld,
    LocationTurnOffPayModal,
    UiTabs,
  },

  mixins: [
    resetMixin(() => {
      const formCountryRelation = {
        feePlan: DEFAULT_FEE_PLAN,
        staffQuantity: null,
        locationQuantity: null,
        tipsType: null,
        taxes: null,
        type: null,
        promoPeriod: 0,
        landingType: null,
        tipDistributions: null,
        distributionType: null,
        primaryStaff: null,
        primaryPercentage: 0,
        primaryTipout: 0,
        workplaceFee: null,
        paymentPage: null,
        payButtonValue: null,
        payoutPlanSetup: null,
        isShowRyftAccount: null,
        paymentSystemUserId: null,
        taxReportingType: null,
        paymentSystem: null,
        tipPayoutType: null,
        caseType: null,

        owners: [],
        troncMasters: [],
        accountants: [],

        groups: [],
        sublocations: [],

        salesPerson: null,
        customerSuccessManager: null,
        timesheetType: null,
        distributionSchemes: null,

        abVariant: null,
        typeOfReview: 'STANDARD',
        googleMapsLink: null,
        tripAdvisorLink: null,
        trustPilotLink: null,

        multiTipsMode: null,
        maxTipsAmount: null,
        isOnlineMenuEnabled: false,
        isEnableSubscriptionPlans: false,
        isMasterLocation: false,
        isQrBillEnabled: false,
        isMarketPlaceEnabled: false,
      };

      return {
        loading: false,
        form: {
          id: 0,
          name: null,
          country: null,
          city: null,
          address: null,
          wish: null,
          groupName: null,
          notes: '',
          partner: {
            name: '',
          },
          payrollFrequency: null,
          payrollDay: null,
          payrollCustomDate: null,
          mode: 'DEFAULT',
          posModel: null,
          [QR_BILL_COMMISSION_LABELS.MERCHANT_FEE]: null,
          [QR_BILL_COMMISSION_LABELS.GATEWAY_FEE]: null,
          [QR_BILL_COMMISSION_LABELS.SETTLEMENT_FEE]: null,
          [QR_BILL_COMMISSION_LABELS.REFUNDS]: null,
          [QR_BILL_COMMISSION_LABELS.CHARGEBACKS]: null,
          [QR_BILL_COMMISSION_LABELS.DAYS_UNTIL_SETTLEMENT]: null,
          [QR_BILL_COMMISSION_LABELS.MINIMUM_SETTLEMENT_AMOUNT]: null,
          [QR_BILL_COMMISSION_LABELS.NO_LIMIT_SETTLEMENT_FEE]: null,
          ...formCountryRelation,
        },
        formCountryRelation,
        imageFile: null,
        imageUrl: null,
        partnerStatus: false,
        partnerImageUrl: null,
        partnerimageFile: null,
        minDate: null,
        maxDate: null,
        activateLocation: {
          show: false,
          date: null,
          callback: () => {},
        },
        turnOffPay: false,
      };
    }),
  ],

  data() {
    return {
      FORM_TABS,
      QR_BILL_COMMISSION_LABELS,
      editable: true,
      showSubscriptionManageModal: false,
      currentFormTab: FORM_TABS.DEFAULT,
    };
  },

  props: {
    formData: Object,
    showCancel: Boolean,
    formLoading: Boolean,
  },

  computed: {
    ...mapState({
      groups: (state) => state.groups.items,
      payButtonsLoaded: (state) => state.settings.loaded.payButtons,
      salesPeopleLoaded: (state) => state.settings.loaded.salesPeople,
      distributionSchemesLoaded: (state) => state.settings.loaded.distributionSchemes,
    }),
    ...mapGetters({
      isAdminOrSales: 'auth/isAdminOrSales',
    }),
    locationCurrency() {
      return getCurrency(this.form.country).label;
    },
    title() {
      return this.formData ? 'Edit location' : 'Create location';
    },
    isUk() {
      return this.form.country === 'gb';
    },
    isGermany() {
      return this.form.country === 'de';
    },
    isUAE() {
      return this.form.country === 'ae';
    },
    isSwitzerland() {
      return this.form.country === 'ch';
    },
    modes() {
      return [
        {
          id: 'DEFAULT',
          label: this.$t('locationModes.default'),
        },
        {
          id: 'DEMO',
          label: this.$t('locationModes.demo'),
        },
        {
          id: 'TEST',
          label: this.$t('locationModes.test'),
        },
      ];
    },
    partnerStatuses() {
      return [
        {
          id: true,
          label: this.$t('partnerStatuses.yes'),
        },
        {
          id: false,
          label: this.$t('partnerStatuses.no'),
        },
      ];
    },
    countries() {
      return this.$getCountries({ i18n: true });
    },
    isTipPayoutEmployer() {
      return this.form.tipPayoutType === 'EMPLOYER';
    },
    isCommon() {
      return this.form.tipsType === 'COMMON'
        || this.form.tipsType === 'COMMON_PRIORITY';
    },
    showDistributionSchemes() {
      return this.isCommon;
    },
    showOnlineMenuEnabledSwitcher() {
      return this.form.type === 'RESTAURANT'
        && this.form.tipsType === 'COMMON';
    },
    showQrBillEnabledSwitcher() {
      const isUAEAndPayermax = this.isUAE
        && this.form.paymentSystem === PAYMENT_SYSTEM.PAYER_MAX;
      const isUkAndRyft = this.isUk
        && this.form.paymentSystem === PAYMENT_SYSTEM.RYFT;

      return isUAEAndPayermax || isUkAndRyft;
    },
    showIntegrations() {
      return this.form.id && this.form.isMarketPlaceEnabled;
    },
    showKYB() {
      return this.form.id && this.isUAE && this.form.paymentSystem === PAYMENT_SYSTEM.PAYER_MAX;
    },
    locationData() {
      return this.formData || {};
    },
    watchPlanSetupList() {
      return [
        this.form?.country || '',
        this.form?.tipsType || '',
      ];
    },
    showPayPageSettings() {
      return this.form.id;
    },
    formTabList() {
      return [
        {
          id: FORM_TABS.DEFAULT,
          title: 'General settings',
          visible: true,
        },
        {
          id: FORM_TABS.QRBILL,
          title: 'Bill settings',
          visible: this.form.isQrBillEnabled,
        },
        {
          id: FORM_TABS.PAY_SETTINGS,
          title: 'Pay page settings',
          visible: this.showPayPageSettings,
        },
        {
          id: FORM_TABS.INTEGRATION_SETTINGS,
          title: 'Integration settings',
          visible: this.showIntegrations,
        },
        {
          id: FORM_TABS.KYB,
          title: 'KYB',
          visible: this.showKYB,
        },
        {
          id: FORM_TABS.KYB_FILES,
          title: 'KYB files',
          visible: this.showKYB,
        },
      ].filter(({ visible }) => visible);
    },
    showMainSubmitActions() {
      return ![FORM_TABS.PAY_SETTINGS, FORM_TABS.INTEGRATION_SETTINGS, FORM_TABS.KYB, FORM_TABS.KYB_FILES].includes(this.currentFormTab);
    },
    getQrBillCommissions() {
      return getQrBillCommissionConfig(this.form.country, this.form.paymentSystem);
    },
    isQrBillSettingsTab() {
      return this.currentFormTab === FORM_TABS.QRBILL;
    },
  },

  watch: {
    formData: {
      immediate: true,
      async handler(payload) {
        const {
          country,
          primaryPercentage,
          salesPerson,
          customerSuccessManager,
          loading,
          editable,
          isOnlineMenuEnabled,
          isEnableSubscriptionPlans,
          isQrBillEnabled,
          isMarketPlaceEnabled,
          ...formData
        } = payload || {};

        this.reset();
        this.loading = true;

        this.form = {
          ...this.form,
          ...formData,
          salesPerson: salesPerson || { id: null },
          customerSuccessManager: customerSuccessManager || { id: null },
          primaryPercentage,
          isOnlineMenuEnabled: Boolean(isOnlineMenuEnabled),
          isEnableSubscriptionPlans: Boolean(isEnableSubscriptionPlans),
          isQrBillEnabled: Boolean(isQrBillEnabled),
          isMarketPlaceEnabled: Boolean(isMarketPlaceEnabled),
        };

        if (editable !== undefined) {
          this.editable = editable;
        }

        if (primaryPercentage) {
          this.form.primaryTipout = 100 - primaryPercentage;
        }

        if (!this.form.partner) {
          this.form.partner = { name: '' };
        }

        if (this.form.partner?.id) {
          this.partnerStatus = Boolean(this.form.partner?.id);
        }

        if (payload) {
          await this.loadCountry(payload);
          await this.loadAvatar(payload);
          if (this.partnerStatus) {
            await this.loadPartnerAvatar(this.form.partner);
          }
          await this.loadUsers({ locationId: payload.id });
          await this.loadGroups({ locationId: payload.id });
          await this.loadSublocations({ locationId: payload.id });
        }

        await this.loadPayButtons();
        await this.loadPayoutPlanSetupList();

        if (!this.salesPeopleLoaded) {
          await this.loadSalesPeople();
        }

        this.loading = false;
      },
    },
    'form.country': {
      async handler(newValue, oldValue) {
        if (this.loading) {
          return;
        }

        this.form.isQrBillEnabled = false;
        this.resetQrBillSettings();

        const similarLogicCounties = ['gb', 'de', 'ch'];
        const newIsSimilarLogicCountry = similarLogicCounties.includes(newValue);
        const oldIsSimilarLogicCountry = similarLogicCounties.includes(oldValue);

        if (newIsSimilarLogicCountry !== oldIsSimilarLogicCountry) {
          const {
            loading,
            ...formData
          } = this.formData || {};

          this.form = {
            ...this.form,
            ...this.formCountryRelation,
            ...formData,
          };
        }

        if (newValue === 'ae') {
          this.form.feePlan = EMIRATES_FEE_PLAN;
          this.form.abVariant = 'DESIGN_ONE_CAPTION';
        } else if (newValue === 'gb') {
          this.form.feePlan = UK_FEE_PLAN;
          this.form.abVariant = 'DESIGN_ONE_CAPTION';
        } else {
          this.form.feePlan = DEFAULT_FEE_PLAN;
          this.form.abVariant = null;
        }

        this.loadPayButtons();
      },
    },
    showQrBillEnabledSwitcher: {
      immediate: false,
      handler(payload) {
        if (payload) {
          return;
        }

        this.form.isQrBillEnabled = false;
      },
    },
    watchPlanSetupList: {
      handler() {
        if (this.loading) {
          return;
        }

        this.loadPayoutPlanSetupList();
      },
    },
    showDistributionSchemes: {
      immediate: true,
      handler(value) {
        if (value && !this.distributionSchemesLoaded) {
          this.loadDistributionSchemes();
        }
      },
    },
    isQrBillSettingsTab: {
      immediate: true,
      handler(payload) {
        if (!payload || !this.getQrBillCommissions) {
          return;
        }

        const fieldsSet = [
          QR_BILL_COMMISSION_LABELS.MERCHANT_FEE,
          QR_BILL_COMMISSION_LABELS.GATEWAY_FEE,
          QR_BILL_COMMISSION_LABELS.SETTLEMENT_FEE,
          QR_BILL_COMMISSION_LABELS.REFUNDS,
          QR_BILL_COMMISSION_LABELS.CHARGEBACKS,
          QR_BILL_COMMISSION_LABELS.DAYS_UNTIL_SETTLEMENT,
          QR_BILL_COMMISSION_LABELS.MINIMUM_SETTLEMENT_AMOUNT,
          QR_BILL_COMMISSION_LABELS.NO_LIMIT_SETTLEMENT_FEE,
        ].every((field) => !this.form[field]);

        if (fieldsSet) {
          const merchantFee = QR_BILL_COMMISSION_LABELS.MERCHANT_FEE;
          const gatewayFee = QR_BILL_COMMISSION_LABELS.GATEWAY_FEE;
          const settlementFee = QR_BILL_COMMISSION_LABELS.SETTLEMENT_FEE;
          const refunds = QR_BILL_COMMISSION_LABELS.REFUNDS;
          const chargebacks = QR_BILL_COMMISSION_LABELS.CHARGEBACKS;
          const daysUntilSettlement = QR_BILL_COMMISSION_LABELS.DAYS_UNTIL_SETTLEMENT;
          const minimumSettlementAmount = QR_BILL_COMMISSION_LABELS.MINIMUM_SETTLEMENT_AMOUNT;
          const noLimitSettlementFee = QR_BILL_COMMISSION_LABELS.NO_LIMIT_SETTLEMENT_FEE;

          this.form[merchantFee] = this.getQrBillCommissions[merchantFee];
          this.form[gatewayFee] = this.getQrBillCommissions[gatewayFee];
          this.form[settlementFee] = this.getQrBillCommissions[settlementFee];
          this.form[refunds] = this.getQrBillCommissions[refunds];
          this.form[chargebacks] = this.getQrBillCommissions[chargebacks];
          this.form[daysUntilSettlement] = this.getQrBillCommissions[daysUntilSettlement];
          this.form[minimumSettlementAmount] = this.getQrBillCommissions[minimumSettlementAmount];
          this.form[noLimitSettlementFee] = this.getQrBillCommissions[noLimitSettlementFee];
        }
      },
    },
  },

  methods: {
    isSubscriptionCountry,
    ...mapActions({
      getGroups: 'groups/loadItems',
      loadCountryPayButtons: 'settings/loadPayButtons',
      loadCountryPayoutPlanSetupList: 'settings/loadPayoutPlanSetupList',
      loadSalesPeople: 'settings/loadSalesPeople',
      loadDistributionSchemes: 'settings/loadDistributionSchemes',
      initDistributions: 'distributions/init',
    }),
    resetQrBillSettings() {
      this.form[QR_BILL_COMMISSION_LABELS.MERCHANT_FEE] = null;
      this.form[QR_BILL_COMMISSION_LABELS.GATEWAY_FEE] = null;
      this.form[QR_BILL_COMMISSION_LABELS.SETTLEMENT_FEE] = null;
      this.form[QR_BILL_COMMISSION_LABELS.REFUNDS] = null;
      this.form[QR_BILL_COMMISSION_LABELS.CHARGEBACKS] = null;
      this.form[QR_BILL_COMMISSION_LABELS.DAYS_UNTIL_SETTLEMENT] = null;
      this.form[QR_BILL_COMMISSION_LABELS.MINIMUM_SETTLEMENT_AMOUNT] = null;
      this.form[QR_BILL_COMMISSION_LABELS.NO_LIMIT_SETTLEMENT_FEE] = null;
    },
    uploadImage(data) {
      this.imageFile = data;
      this.imageUrl = URL.createObjectURL(data);
      if (this.formData?.id) {
        this.uploadAvatar({ id: this.formData.id });
      }
    },
    uploadPartnerImage(data) {
      this.partnerImageFile = data;
      this.partnerImageUrl = URL.createObjectURL(data);
      if (this.form.partner?.id) {
        this.uploadPartnerAvatar({ id: this.form.partner?.id });
      }
    },
    castInt(value) {
      return parseInt(value, 10) || 0;
    },
    loadCountry({ country }) {
      this.form.country = getCountry(country, null)?.iso;
    },
    async loadAvatar({ id }) {
      try {
        const { data } = await api.locations.getImage({
          locationId: id,
        });

        if (data && data.size > 0) {
          this.imageUrl = URL.createObjectURL(data);
        }
      } catch (e) {
        // noop.
      }
    },
    async loadPartnerAvatar({ id }) {
      try {
        const { data } = await api.locations.getPartnerImage({ partnerId: id });

        if (data && data.size > 0) {
          this.partnerImageUrl = URL.createObjectURL(data);
        }
      } catch (e) {
        // noop.
      }
    },
    loadUsers(payload) {
      if (!this.isUk && !this.isGermany && !this.isUAE && !this.isSwitzerland) {
        return false;
      }

      const users = [
        'owners',
        'troncMasters',
        'accountants',
      ];

      return Promise.all(
        users.map(async (attribute) => {
          try {
            const { data } = await api[attribute].getList(payload);

            if (data) {
              this.form[attribute] = [
                ...data
                  .filter((item) => item.status !== 'NOT_ACTIVE')
                  .map((item) => ({
                    ...item,
                    oldPhoneNumber: item.phoneNumber,
                    oldEmail: item.email,
                    newest: false,
                    removed: false,
                  })),
              ];
            }
          } catch (e) {
            this.$showServerError(e);
          }
        }),
      );
    },
    async loadGroups(payload) {
      if (this.form.paymentPage !== 'GROUPS') {
        return;
      }

      try {
        await this.getGroups(payload);

        this.form.groups = this.groups.map((item) => ({
          ...item,
          removed: false,
          newest: false,
        }));
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async loadSublocations(payload) {
      if (!this.form.isMasterLocation) {
        return;
      }

      try {
        const { data } = await api.locations.getSublocations(payload);

        this.form.sublocations = data;
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async loadPayButtons() {
      if (!this.isUk && !this.isGermany && !this.isUAE && !this.isSwitzerland) {
        return;
      }

      this.loading = true;

      try {
        await this.loadCountryPayButtons({ country: this.form.country });
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
    async loadPayoutPlanSetupList() {
      if (!this.isUAE && !this.isUk) {
        return;
      }

      this.loading = true;

      try {
        await this.loadCountryPayoutPlanSetupList({
          country: this.form.country,
          tipsType: this.form.tipsType,
        });
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
    async perform({ validate, method, resetAfter }) {
      if (!await validate()) {
        this.$warning({
          message: this.$t('alerts.requiredModal.message'),
        });

        return;
      }

      this.loading = true;

      try {
        const {
          promoPeriod,
          landingType,
          tipDistributions,
          owners,
          troncMasters,
          accountants,
          distributionType,
          primaryTipout,
          groups,
          salesPerson,
          partner,
          customerSuccessManager,
          distributionSchemes,
          ...formData
        } = this.form;

        formData.salesPerson = salesPerson?.id ? salesPerson : null;
        formData.customerSuccessManager = customerSuccessManager?.id ? customerSuccessManager : null;

        if (promoPeriod) {
          formData.promoPeriod = promoPeriod;
        }

        if (distributionType) {
          formData.distributionType = distributionType;
        }

        if (primaryTipout) {
          formData.primaryPercentage = 100 - primaryTipout;
        }

        if (formData.isQrBillEnabled) {
          formData[QR_BILL_COMMISSION_LABELS.MERCHANT_FEE] = +this.form[QR_BILL_COMMISSION_LABELS.MERCHANT_FEE];
          formData[QR_BILL_COMMISSION_LABELS.GATEWAY_FEE] = +this.form[QR_BILL_COMMISSION_LABELS.GATEWAY_FEE];
          formData[QR_BILL_COMMISSION_LABELS.SETTLEMENT_FEE] = +this.form[QR_BILL_COMMISSION_LABELS.SETTLEMENT_FEE];
          formData[QR_BILL_COMMISSION_LABELS.REFUNDS] = +this.form[QR_BILL_COMMISSION_LABELS.REFUNDS];
          formData[QR_BILL_COMMISSION_LABELS.CHARGEBACKS] = +this.form[QR_BILL_COMMISSION_LABELS.CHARGEBACKS];
          formData[QR_BILL_COMMISSION_LABELS.DAYS_UNTIL_SETTLEMENT] = +this.form[QR_BILL_COMMISSION_LABELS.DAYS_UNTIL_SETTLEMENT];
          formData[QR_BILL_COMMISSION_LABELS.MINIMUM_SETTLEMENT_AMOUNT] = +this.form[QR_BILL_COMMISSION_LABELS.MINIMUM_SETTLEMENT_AMOUNT];
          formData[QR_BILL_COMMISSION_LABELS.NO_LIMIT_SETTLEMENT_FEE] = +this.form[QR_BILL_COMMISSION_LABELS.NO_LIMIT_SETTLEMENT_FEE];
        }

        if (this.showDistributionSchemes) {
          formData.distributionSchemes = distributionSchemes;
        }

        if (this.partnerStatus) {
          formData.partner = partner;
        } else {
          formData.partner = null;
        }

        const { data: location } = await method(formData);

        await this.uploadAvatar(location);
        if (this.partnerStatus) {
          await this.uploadPartnerAvatar(location.partner);
        }
        await this.saveUsers(location);
        await this.saveGroups(location);
        await this.saveSublocations(location);

        if (this.showDistributionSchemes) {
          this.initDistributions({ location });
        }

        if (resetAfter) {
          this.reset();
        }

        this.$emit('completed', location);
        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
    create(validate) {
      return this.perform({
        method: api.locations.createLocation,
        resetAfter: true,
        validate,
      });
    },
    save(validate) {
      return this.perform({
        method: api.locations.updateLocation,
        resetAfter: false,
        validate,
      });
    },
    async activate() {
      this.minDate = new Date(this.formData.createdDate);
      this.minDate.setDate(this.minDate.getDate() - 1);

      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 1);

      return new Promise((callback) => {
        this.activateLocation = {
          show: true,
          date: null,
          callback,
        };
      }).then(async (result) => {
        if (result) {
          await api.locations.activateLocation({
            id: this.formData.id,
            onboardingDate: this.activateLocation.date,
          });

          this.$emit('update', {
            ...this.formData,
            status: 'ACTIVE',
            onboardingDate: toSQLDate(this.activateLocation.date),
          });
        }
      });
    },
    async handleDeactivate() {
      this.turnOffPay = true;
    },
    deactivate(removeReason) {
      this.$emit('update', {
        ...this.formData,
        status: 'NOT_ACTIVE',
        removeReason,
      });
    },
    async uploadAvatar({ id }) {
      if (this.imageFile) {
        await api.locations.uploadImage({
          locationId: id,
          file: this.imageFile,
        });
      }
    },
    async uploadPartnerAvatar({ id }) {
      if (this.partnerImageFile) {
        await api.locations.uploadPartnerImage({
          partnerId: id,
          file: this.partnerImageFile,
        });
      }
    },
    saveGroups(location) {
      if (!this.isUk && !this.isGermany && !this.isUAE && !this.isSwitzerland) {
        return false;
      }

      return Promise.all(
        this.form.groups.map(({ removed, newest, ...item }) => {
          if (removed) {
            return api.locations.removeGroup({
              locationId: location.id,
              groupId: item.id,
            });
          }

          if (newest) {
            return api.locations.createGroup({
              locationId: location.id,
              ...item,
            });
          }

          return api.locations.updateGroup({
            locationId: location.id,
            groupId: item.id,
            ...item,
          });
        }),
      );
    },
    saveSublocations(location) {
      if (!this.form.isMasterLocation) {
        return false;
      }

      return api.locations.setSublocation(location.id, this.form.sublocations);
    },
    saveUsers(location) {
      if (!this.isUk && !this.isGermany && !this.isUAE && !this.isSwitzerland) {
        return false;
      }

      const users = [
        'owners',
        'troncMasters',
        'accountants',
      ];

      // Write hard, go pro.
      return Promise.all(
        users.map((attribute) => {
          const oldUsers = this.form[attribute]
            .filter(({ newest }) => !newest);

          const newUsers = this.form[attribute]
            .filter(({ removed }) => !removed);

          const removeUsers = oldUsers
            .filter(({ oldPhoneNumber }) => {
              return !newUsers.some(({ phoneNumber }) => phoneNumber === oldPhoneNumber);
            });

          const createUsers = newUsers
            .filter(({ id, phoneNumber }, index, list) => {
              return !list.some((item, jndex) => {
                return item.id !== id
                  && item.phoneNumber === phoneNumber
                  && index > jndex;
              });
            })
            .filter(({ phoneNumber }) => {
              return !oldUsers.some(({ oldPhoneNumber }) => oldPhoneNumber === phoneNumber);
            });

          // Add PUT endpoint for other roles if you need emails for them
          const updateUsers = attribute === 'owners' ? oldUsers
            .filter(({ email, oldEmail }) => email !== oldEmail)
            .filter((updateUser) => {
              return !createUsers.some((createUser) => updateUser.id === createUser.id);
            }) : [];

          return Promise.all([
            ...removeUsers.map((item) => {
              return api[attribute].deactivate({
                id: item.id,
                locationId: location.id,
              });
            }),
            ...createUsers.map(async (item) => {
              try {
                await api[attribute].create({
                  id: hex(16),
                  phoneNumber: item.phoneNumber,
                  email: item.email,
                  locationId: location.id,
                });
              } catch (e) {
                if (e?.response?.data?.errorCode !== 422002) {
                  throw e;
                }
              }
            }),
            ...updateUsers.map(async (item) => {
              try {
                await api[attribute].update({
                  id: item.id,
                  email: item.email,
                  locationId: location.id,
                });
              } catch (e) {
                if (e?.response?.data?.errorCode !== 422002) {
                  throw e;
                }
              }
            }),
          ]);
        }),
      );
    },
    async toggleSubscriptionPlansHandler(newValue) {
      if (
        await this.$confirm({
          title: newValue ? 'Enable plans' : 'Disable plans',
          message: newValue
            ? 'You are about to switch ON</br>subscription plans for the venue.</br></br>Features will be activated</br>depending on the subscription plan</br>and payment.'
            : 'You are about to switch OFF</br>subscription plans for the venue.</br></br>All features will be activated</br>immediately, completely and</br>unconditionally.',
          applyButton: 'Confirm',
          titleSize: 'lg',
        })
      ) {
        this.form.isEnableSubscriptionPlans = newValue;
      } else {
        this.form.isEnableSubscriptionPlans = !newValue;
      }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
<style lang="scss">
  .location-form {
    &__actions {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }
  }
</style>
