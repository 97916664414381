import { PAYMENT_SYSTEM } from '@/config/paymentSystems';

const {
  // gb ryft qr bill commissions
  VUE_APP_GB_RYFT_MERCHANT_FEE,
  VUE_APP_GB_RYFT_GATEWAY_FEE,
  VUE_APP_GB_RYFT_SETTLEMENT_FEE,
  VUE_APP_GB_RYFT_REFUNDS,
  VUE_APP_GB_RYFT_CHARGEBACKS,
  VUE_APP_GB_RYFT_DAYS_UNTIL_SETTLEMENT,
  VUE_APP_GB_RYFT_MINIMUM_SETTLEMENT_AMOUNT,
  VUE_APP_GB_RYFT_NO_LIMIT_SETTLEMENT_FEE,

  // ae payermax qr bill commissions
  VUE_APP_AE_PAYER_MAX_MERCHANT_FEE,
  VUE_APP_AE_PAYER_MAX_GATEWAY_FEE,
  VUE_APP_AE_PAYER_MAX_SETTLEMENT_FEE,
  VUE_APP_AE_PAYER_MAX_REFUNDS,
  VUE_APP_AE_PAYER_MAX_CHARGEBACKS,
  VUE_APP_AE_PAYER_MAX_DAYS_UNTIL_SETTLEMENT,
  VUE_APP_AE_PAYER_MAX_MINIMUM_SETTLEMENT_AMOUNT,
  VUE_APP_AE_PAYER_MAX_NO_LIMIT_SETTLEMENT_FEE,
} = process.env;

export const QR_BILL_COMMISSION_LABELS = {
  MERCHANT_FEE: 'processingVariableCommission',
  GATEWAY_FEE: 'processingFixedCommission',
  SETTLEMENT_FEE: 'processingSettlementCommission',
  REFUNDS: 'refunds',
  CHARGEBACKS: 'chargebacks',
  DAYS_UNTIL_SETTLEMENT: 'daysUntilSettlement',
  MINIMUM_SETTLEMENT_AMOUNT: 'minimumSettlementThreshold',
  NO_LIMIT_SETTLEMENT_FEE: 'noLimitSettlementFee',
};

export const QR_BILL_COMMISSION_CONFIG = {
  gb: {
    [PAYMENT_SYSTEM.RYFT]: {
      [QR_BILL_COMMISSION_LABELS.MERCHANT_FEE]: parseFloat(VUE_APP_GB_RYFT_MERCHANT_FEE),
      [QR_BILL_COMMISSION_LABELS.GATEWAY_FEE]: parseFloat(VUE_APP_GB_RYFT_GATEWAY_FEE),
      [QR_BILL_COMMISSION_LABELS.SETTLEMENT_FEE]: parseFloat(VUE_APP_GB_RYFT_SETTLEMENT_FEE),
      [QR_BILL_COMMISSION_LABELS.REFUNDS]: parseFloat(VUE_APP_GB_RYFT_REFUNDS),
      [QR_BILL_COMMISSION_LABELS.CHARGEBACKS]: parseFloat(VUE_APP_GB_RYFT_CHARGEBACKS),
      [QR_BILL_COMMISSION_LABELS.DAYS_UNTIL_SETTLEMENT]: parseInt(VUE_APP_GB_RYFT_DAYS_UNTIL_SETTLEMENT, 10),
      [QR_BILL_COMMISSION_LABELS.MINIMUM_SETTLEMENT_AMOUNT]: parseFloat(VUE_APP_GB_RYFT_MINIMUM_SETTLEMENT_AMOUNT),
      [QR_BILL_COMMISSION_LABELS.NO_LIMIT_SETTLEMENT_FEE]: parseFloat(VUE_APP_GB_RYFT_NO_LIMIT_SETTLEMENT_FEE),
    },
  },
  ae: {
    [PAYMENT_SYSTEM.PAYER_MAX]: {
      [QR_BILL_COMMISSION_LABELS.MERCHANT_FEE]: parseFloat(VUE_APP_AE_PAYER_MAX_MERCHANT_FEE),
      [QR_BILL_COMMISSION_LABELS.GATEWAY_FEE]: parseFloat(VUE_APP_AE_PAYER_MAX_GATEWAY_FEE),
      [QR_BILL_COMMISSION_LABELS.SETTLEMENT_FEE]: parseFloat(VUE_APP_AE_PAYER_MAX_SETTLEMENT_FEE),
      [QR_BILL_COMMISSION_LABELS.REFUNDS]: parseFloat(VUE_APP_AE_PAYER_MAX_REFUNDS),
      [QR_BILL_COMMISSION_LABELS.CHARGEBACKS]: parseFloat(VUE_APP_AE_PAYER_MAX_CHARGEBACKS),
      [QR_BILL_COMMISSION_LABELS.DAYS_UNTIL_SETTLEMENT]: parseInt(VUE_APP_AE_PAYER_MAX_DAYS_UNTIL_SETTLEMENT, 10),
      [QR_BILL_COMMISSION_LABELS.MINIMUM_SETTLEMENT_AMOUNT]: parseFloat(VUE_APP_AE_PAYER_MAX_MINIMUM_SETTLEMENT_AMOUNT),
      [QR_BILL_COMMISSION_LABELS.NO_LIMIT_SETTLEMENT_FEE]: parseFloat(VUE_APP_AE_PAYER_MAX_NO_LIMIT_SETTLEMENT_FEE),
    },
  },
};

export const getQrBillCommissionConfig = (country, paymentSystem) => {
  if (!country || !paymentSystem) {
    return null;
  }

  return QR_BILL_COMMISSION_CONFIG[country]?.[paymentSystem];
};
