export const INTEGRATION = {
  ALTEGIO: 'ALTEGIO',
};

export const INTEGRATION_LABEL = {
  [INTEGRATION.ALTEGIO]: 'Altegio',
};

export const INTEGRATION_STATUS = {
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
};

export const INTEGRATION_STATUS_CONFIG = {
  [INTEGRATION_STATUS.CONNECTED]: {
    label: 'Connected',
    color: '#23BC85',
  },
  [INTEGRATION_STATUS.DISCONNECTED]: {
    label: 'Disconnected',
    color: '#EF3024',
  },
};
